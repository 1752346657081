import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import Img from 'gatsby-image'
import SEO from "../components/seo"
import { getParagraph } from "./fragments/paragraphsHelper"

const Template = ({ data, location }) => {
  const repair = data.nodeRepair
  const paragraphs = repair.relationships.field_content.map(getParagraph)
    return (
      <Layout location={location} nid={repair.drupal_internal__nid}>
        <SEO title={repair.title} />
        <section className="container mx-auto px-8">
          <div className="page-title mb-4">
            {repair.title}
          </div>
          {repair.relationships.field_image &&
            <div
              className="float-right ml-3 mb-2 w-full md:w-1/3 shadow-lg"
              >
              <Img fluid={
                repair.relationships.field_image.localFile.childImageSharp.fluid
              } />
            </div>
          }
          {paragraphs}
        </section>
      </Layout>
    )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Template;

export const query = graphql`
  query($repairId: String!) {
    nodeRepair(id: { eq: $repairId }) {
      id
      drupal_internal__nid
      title
      path {
        alias
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 650) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        field_content {
          type: __typename
          ...ParagraphsContent
          ...ParagraphsImage
        }
      }
    }
  }`